<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">เพิ่มประเภทสินค้า</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            @submit="onSubmitProduct_type"
            :validation-schema="product_type"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">


                 <BaseInput
                  label="ประเภทสินค้า"
                  required="required"
                  type="text"
                  name="name"
                />

    
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/modals/BaseInput.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useProductType from "@/core/services/api/travel/entrepreneur/product_type";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
export default defineComponent({
  name: "entrepreneur-product-type-create",
  components: {
    Form,
    BaseInput
  },
  setup() {
    const { stoerProductType } = useProductType();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);

    const product_type = Yup.object().shape({
      name: Yup.string()
        .required("กรุณากรอก ประเภทสินค้า")
        .label("ประเภทสินค้า"),
    });

    const onSubmitProduct_type = (values) => {
      Sconfirm("ยืนยัน การเพิ่มประเภทสินค้า", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }
            await stoerProductType(values)
              .then(() => {
                SToast("success", "เพิ่ม ประเภทสินค้า สำเร็จ");
              })
              .catch((error) => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.name) {
                    SToast("error", `${errors.name[0]}`);
                  }
                }
              });
          }
        }
      );
    };



    onMounted(() => {
      checkPage("entrepreneur-product-type-create");
      setCurrentPageBreadcrumbs("เพิ่มประเภทสินค้า", ["ส่งเสริมการท่องเที่ยว","ผู้ประกอบการ","ประเภทสินค้า"]);
    });

    return {
      product_type,
      submitButton,
      onSubmitProduct_type,

    };
  },
});
</script>
<style scoped>
</style>
